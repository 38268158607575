import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import {Stack, Alert, Box} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {useForm} from 'react-hook-form';
import FormProvider from '../../../components/rxf-form-provider';
import RHFTextField from '../../../components/rhf-text-field';
// ----------------------------------------------------------------------

ResetPasswordForm.propTypes = {
    onSuccess: PropTypes.func.isRequired,
    token: PropTypes.string.isRequired,
};

export default function ResetPasswordForm({onSuccess, token}) {
    const resetPasswordFormSchema = yup.object({
        password: yup.string().min(8, 'Minimal parol uzunligi 8 ta').required('Parolni kiriting'),
    });

    const defaultValues = {
        password: '',
    };

    const methods = useForm({
        resolver: yupResolver(resetPasswordFormSchema),
        defaultValues,
    });

    const {
        reset,
        setError,
        handleSubmit,
        formState: {errors, isSubmitting},
    } = methods;

    async function setPassword(password) {
        return fetch(`https://order-system.up.railway.app/api/v1/auth/password/set?newPassword=${password}`, {
            method: 'PATCH',
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                newPassword: password,
            }),
        });
    }

    const onSubmit = async (data) => {
        await setPassword(data.password)
            .then((res) => res.json())
            .then((data) => {
                if (data.error) {
                    throw data;
                }
                onSuccess();
            }).catch(error => {
                let errorMsg = '';
                const errData = error.error?.data?.errors ?? error.error?.data;
                for (let i = 0; i < errData?.length; i += 1) {
                    errorMsg += ` ${errData[i].message}`;
                }
                reset();
                setError('root.register', {
                    message: errorMsg,
                });
            })
    }

    return (
        <>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Box>
                    <Stack spacing={3} sx={{my: 2}}>
                        {errors && errors.root && errors.root.register && (
                            <Alert severity="error" sx={{marginBottom: 2}}>
                                {errors.root.register.message}
                            </Alert>
                        )}
                        <RHFTextField name={'password'} label="Tasdiqlash parolini yozing"/>
                    </Stack>
                    <LoadingButton fullWidth size="large" type="submit" variant="contained"
                                   loading={isSubmitting}>
                        Ro'yxatdan o'tish
                    </LoadingButton>
                </Box>
            </FormProvider>
        </>
    )
}
