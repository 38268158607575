import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  SEARCHED_ORDERS_LIST: [],
};

export const orderReducerSlice = createSlice({
  name: 'orderSlice',
  initialState,
  reducers: {
    searchOrder: (state, { payload }) => {
      state.SEARCHED_ORDERS_LIST = payload
    },
  },
});

export const { searchOrder } =
  orderReducerSlice.actions;

export default orderReducerSlice.reducer;
