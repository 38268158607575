import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import axios from 'axios'
import Cookies from 'universal-cookie'

const cookies = new Cookies();

export const axiosInstance = axios.create({
  baseURL: 'https://api.milliydarvozalar.uz/api/v1/',
});

axiosInstance.interceptors.request.use((req) => {
  const token = cookies.get('token');

  if (token) {
    req.headers = {
      Authorization: `Bearer ${token}`,
    };
  }
  return req;
});

export async function getData({ url, config }) {
  return await axiosInstance.get(url, config && {...config});
}

export const baseApi = createApi({
  reducerPath: 'baseURL',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://api.milliydarvozalar.uz/api/v1/',
    prepareHeaders: (headers) => {
      const rtkToken = cookies.get('token');
      if (rtkToken) {
        headers.set('Authorization', `Bearer ${rtkToken}`);
      }
      return headers;
    },
  }),
  tagTypes: ['ORDER', 'USER'],
  endpoints: () => ({}),
});
