import PropTypes from 'prop-types';
// @mui
import { Box, Card, Link, Typography, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';

// components
import Label from '../../../components/label';
import {getStatusTranslation, statusColor} from '../../../utils/statusFormat'
import {useNavigate} from 'react-router-dom'


// ----------------------------------------------------------------------

const StyledProductImg = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

// ----------------------------------------------------------------------

ShopProductCard.propTypes = {
  product: PropTypes.object,
};

const avatarUrl = `https://api.milliydarvozalar.uz/api/v1/image/view/`
export default function ShopProductCard({ product }){
  const navigate = useNavigate()
  const { id, name, status, imagesToken, address } = product;
  let newStatus = getStatusTranslation(status)
  let newStatusColor = statusColor(status)
  const handleClick = () => {
    navigate(`/order/${id}`)
  }
  return (
    <Card onClick={handleClick} sx={{cursor:'pointer'}}>
      <Box sx={{ pt: '100%', position: 'relative' }}>
        {status && (
          <Label
            variant="filled"
            color={newStatusColor}
            sx={{
              zIndex: 9,
              top: 16,
              right: 16,
              position: 'absolute',
              textTransform: 'uppercase',
            }}
          >
            {newStatus}
          </Label>
        )}
        <StyledProductImg alt={name} src={`${avatarUrl}${imagesToken[0]}`} />
      </Box>

      <Stack spacing={2} sx={{ p: 3 }}>
        <Link color="inherit" underline="hover">
          <Typography variant="subtitle2" noWrap>
            {name}
          </Typography>
        </Link>

        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="subtitle1">
           Manzil: {address}
          </Typography>
        </Stack>
      </Stack>
    </Card>
  );
}
