import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function AlertDialog({ id,openDialog, setOpenDialog, updateCheckedStatus }) {
  const handleClose = () => {
    setOpenDialog(false);
  };
  const handleClick = ()=>{
    updateCheckedStatus(id,'GOT_PAID')
    handleClose()
  }

  return (
    <div>
      <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Buyurtmani hisob-kitob qilingan ishlar bo'limiga o'tkazish."}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Qaroringiz qat'iymi?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color={'warning'}>Yo'q</Button>
          <Button onClick={handleClick} autoFocus>
            Ha
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
