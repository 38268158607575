import { useCallback,useState } from 'react';
import { Alert, Box, Container, Grid, Snackbar, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useCreateOrderMutation } from '../../../api/order-api/order-api-slice';
import { useUploadImageMutation } from '../../../api/img-upload-api';

import { FileUploadInput } from '../../../components/file-upload';
import FormProvider from '../../../components/rxf-form-provider';
import { RHFDataPicker } from '../../../components/rhfdatapicker';
import RHFTextField from '../../../components/rhf-text-field';


export const NewOrder = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [datas, setData] = useState([])
  const [success,setSuccess] = useState(false)
  const navigate = useNavigate();

  const orderFormSchema = yup.object({
    deliveredDate: yup.string().required('Yetkazib berish sanasini kiriting'),
    name: yup.string().required('Buyurtma nomini kiriting'),
    size: yup.string().required('Razmerini kiriting'),
    price: yup.string().required('Narhini kiriting'),
    description: yup.string().required('Qisqacha malumot kiriting'),
    acceptedDate: yup.string().required('Qabul qilish sanasini kiriting'),
    fullName: yup.string().required('Mijozning ismini kiriting'),
    agreement: yup.string().required('Kelishuv haqida qisqacha kiriting'),
    color: yup.string().required('Buyurtma rangini kiriting'),
    phoneNumber: yup.string().max(13, 'Telefon raqam formati xato!').required('Telefon raqamini kiriting'),
    address: yup.string().required('Manzilni kiriting'),
    materials: yup.string().required('Materialler talab etiladi'),
  });

  const defaultValues = {
    name: '',
    size: '',
    price: '',
    description: '',
    acceptedDate: '',
    fullName: '',
    agreement: '',
    deliveredDate: '',
    phoneNumber: '',
    address: '',
    color:''
  };

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(orderFormSchema),
  });

  const {
    formState: { errors, isSubmitting },
    handleSubmit,
    setError,
  } = methods;

  function formarData(data) {
    const date = new Date(data);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  const onSubmit = async (data) => {
      await createOrder({
      ...data,
      acceptedDate: formarData(data.acceptedDate),
      deliveredDate: formarData(data.deliveredDate),
    })
      .then((res) => {
        if (res?.error) {
          throw res;
        }
        if (!selectedFile) navigate('/works');
        setData(res?.data)
      })
      .catch((error) => {
        let errorMsg = '';
        const errData = error.error?.data?.errors ?? error.error?.data;
        for (let i = 0; i < errData?.length; i += 1) {
          errorMsg += ` ${errData[i].message}`;
        }
        setError('root.register', {
          message: errorMsg,
        });
      });
  };

  const [createOrder, createOrderRes] = useCreateOrderMutation();
  const [fileUpload] = useUploadImageMutation();

  const imageUploadReq = useCallback(async() => {
    if (selectedFile && createOrderRes?.data) {
      const formData = new FormData();
      const newSelectedFile = [...selectedFile];
      newSelectedFile?.forEach((file) => {
        formData.append('file', file);
      });

      await fileUpload({ id: datas?.data?.id, body: formData }).then((res) => {
        if (res?.error) throw res;
        setSuccess(true)
        navigate('/works');
      });
    }
  }, [selectedFile, createOrderRes.data, datas?.data?.id,fileUpload, navigate]);

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={success}
        autoHideDuration={500}
        message={'Buyurtma mufaqqiyatli yaratildi'}
      />
      <Container>
        <FormProvider methods={methods} onSubmit={handleSubmit(datas?.data?.id?imageUploadReq:onSubmit)}>
          <Stack gap={2}>
            <Box>
              <Grid container gap={3}>
                <Grid item md={12}>
                  {errors && errors.root && errors.root.register && (
                    <Alert severity="error">{errors.root.register.message}</Alert>
                  )}
                </Grid>
                <Grid item md={3} xs={12}>
                  <RHFTextField name={'orderTaker'} label={'Buyurtma oluvchi'} />
                </Grid>
                <Grid item md={3} xs={12}>
                  <RHFTextField name={'name'} label={'Buyurtma nomi'} />
                </Grid>
                <Grid item md={3} xs={12}>
                  <RHFTextField name={'size'} multiline label={'Buyurtma razmeri'} />
                </Grid>
                <Grid item md={3} xs={12}>
                  <RHFTextField name={'materials'} multiline label={'Materiallar'} />
                </Grid>
                <Grid item md={3} xs={12}>
                  <RHFTextField name={'color'} multiline label={'Rangi'} />
                </Grid>
                <Grid item md={3} xs={12}>
                  <RHFTextField name={'description'} multiline label={"Hisob kitob"} />
                </Grid>
                <Grid item md={3} xs={12}>
                  <RHFTextField name={'fullName'} label={"Mijozning ismi"} />
                </Grid>
                <Grid item md={3} xs={12}>
                  <RHFTextField
                   name={'phoneNumber'}
                   label={'Mijozning telefon raqami'}
                   inputProps={{
                     maxLength: 13,
                   }}
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                  <RHFTextField name={'address'} label={'Mijozning manzili'} />
                </Grid>

                <Grid item md={3} xs={12}>
                  <RHFTextField name={'agreement'} label={'Avans'} />
                </Grid>

                <Grid item md={3} xs={12}>
                  <RHFTextField name={'price'} label={'Buyurtma narhi'} />
                </Grid>

                <Grid item md={3} xs={12}>
                  <RHFDataPicker name={'acceptedDate'} label={'Qabul qilingan sana'} />
                </Grid>
                <Grid item md={3} xs={12}>
                  <RHFDataPicker name={'deliveredDate'} label={'Yetkazib berish sana'} />
                </Grid>
              </Grid>
            </Box>
            <Box>
              <FileUploadInput
               selectedFile={selectedFile}
               name={'image'}
               label={'Rasm yuklash'}
               setSelectedFile={setSelectedFile}
              />
            </Box>
            <Box sx={{ marginTop: 2 }}>
              <LoadingButton size="large" type="submit" variant="contained" loading={isSubmitting}>
                {datas?.data?.id?'Yangi buyurtma yaratish':'Buyurtma rasmini yuklash'}
              </LoadingButton>
            </Box>
          </Stack>
        </FormProvider>
      </Container>
    </>
  );
};
