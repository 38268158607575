import {useState} from 'react'
import {Helmet} from 'react-helmet-async'
// @mui
import {Container, Stack, Typography} from '@mui/material'
// components
import {ProductList, ProductSort} from '../../../sections/@dashboard/products'
// mock
import {useGetOrderListQuery} from '../../../api/order-api/order-api-slice'
import {propOr} from 'rambda'
import {useSelector} from 'react-redux'

// ----------------------------------------------------------------------

export default function OrdersPage() {
  const [status, setStatus] = useState('ALL')
  const orders = useSelector((state) => state.order)
  const getDatas = propOr([], 'SEARCHED_ORDERS_LIST', orders)

  const {
    isSuccess,
  } = useGetOrderListQuery(
    {
      pageNumber: 0,
      itemSize: 1000,
      status: status,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  )
  return (
    <>
      <Helmet>
        <title> Dashboard: Products | Minimal UI </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{mb: 5}}>
          <Typography variant="h4">
            Buyurtmalar
          </Typography>
          <ProductSort setStatus={setStatus}/>
        </Stack>
        <ProductList products={getDatas} loading={isSuccess}/>
      </Container>
    </>
  )
}
