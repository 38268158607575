export function getStatusTranslation(status) {
  let translatedStatus;

  switch (status) {
    case 'RECEIVED':
      translatedStatus = 'Yangi';
      break;
    case 'STARTED':
      translatedStatus = 'Yasalmoqda';
      break;
    case 'PAINTING':
      translatedStatus = 'Bo\'yalmoqda';
      break;
    case 'FINISHED':
      translatedStatus = 'O\'rnatishga tayyor';
      break;
    case 'DELIVERED':
      translatedStatus = 'O\'rnatilgan';
      break;
    default:
      translatedStatus = 'Yangi'; // Handle unrecognized status values
  }

  return translatedStatus;
}

export function statusColor(status) {
  let translatedStatus;

  switch (status) {
    case 'RECEIVED':
      translatedStatus = 'warning';
      break;
    case 'STARTED':
      translatedStatus = 'info';
      break;
    case 'PAINTING':
      translatedStatus = 'info';
      break;
    case 'FINISHED':
      translatedStatus = 'error';
      break;
    case 'DELIVERED':
      translatedStatus = 'success';
      break;
    default:
      translatedStatus = 'warning'; // Handle unrecognized status values
  }

  return translatedStatus;
}