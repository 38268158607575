import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Container, Typography } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Logo from '../components/logo';
// sections
import { ResetPasswordForm } from '../sections/auth/reset-password';
import { EmailVerify } from '../components/email-verify';
import { usePasswordTokenVerifyQuery } from '../api/auth-api/auth-api-slice';
import Loader from '../components/loader/Loader';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function ResetPasword() {
  const mdUp = useResponsive('up', 'md');

  const [onSend, setSend] = useState(false);

  const { token } = useParams();

  const { isSuccess, isLoading, isFetching, data } = usePasswordTokenVerifyQuery({
    token,
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (!token || (!isSuccess && !isLoading)) {
      navigate('/404');
    }
  }, [token, navigate, isSuccess, isLoading]);

  if (isFetching) {
    return <Loader />;
  }

  return (
    <>
      <Helmet>
        <title> Parolni O'zgartirish | Al Baraka </title>
      </Helmet>

      <StyledRoot>
        <Logo
          sx={{
            position: 'fixed',
            top: { xs: 16, sm: 24, md: 40 },
            left: { xs: 16, sm: 24, md: 40 },
          }}
        />

        {mdUp && (
          <StyledSection>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              Salom, Qaytganingizdan xursandmiz!
            </Typography>
            <img src="/assets/illustrations/illustration_login.png" alt="login" />
          </StyledSection>
        )}

        <Container maxWidth="sm">
          <StyledContent>
            {onSend ? (
              <EmailVerify message="Parolingiz mufaqqiyatli yangilandi" />
            ) : (
              <>
                <Typography variant="h4" gutterBottom>
                  Parolni yangilash
                </Typography>
                <ResetPasswordForm token={data?.message} onSuccess={() => setSend(true)} />
              </>
            )}
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
