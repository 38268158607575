import PropTypes from 'prop-types'
// @mui
import {TableCell, TableHead, TableRow, TableSortLabel} from '@mui/material'

// ----------------------------------------------------------------------

UserListHead.propTypes = {
  rowCount: PropTypes.number,
  headLabel: PropTypes.array,
  numSelected: PropTypes.number,
  onRequestSort: PropTypes.func,
  onSelectAllClick: PropTypes.func,
}

export default function UserListHead({
                                       headLabel,
                                     }) {
  return (
    <TableHead>
      <TableRow height={70}>
        <TableCell>
          Yana
        </TableCell>
        {headLabel.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
          >
            <TableSortLabel
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}
