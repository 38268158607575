import * as React from 'react'
import {useState} from 'react'
import {IconButton, MenuItem, Popover} from '@mui/material'
import Iconify from '../../../components/iconify'
import {Link} from 'react-router-dom'
import AlertDialog from './DoingOrderDialog'

function DoingOrderRowMenu({row, orderDelete, updateCheckedMaster}) {
  const role = JSON.parse(localStorage.getItem('role'))
  const [open, setOpen] = useState(null)
  const [openDialog, setOpenDialog] = React.useState(false)

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setOpen(null)
  }

  const handleClickOpenDialog = () => {
    setOpenDialog(true)
    setOpen(null)
  }

  return (
    <>
      <IconButton size="large" color="inherit" onClick={handleOpenMenu}>
        <Iconify icon={'eva:more-vertical-fill'}/>
      </IconButton>
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
        transformOrigin={{vertical: 'right', horizontal: 'left'}}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem sx={{color: 'success'}} onClick={handleClickOpenDialog}>
          <Iconify icon={'mdi:tick-decagram'} sx={{mr: 2}}/>
          Qabul qilish
        </MenuItem>
        <Link to={`/order/${row.id}`}>
          <MenuItem>
            <Iconify icon={'ph:eye-bold'} sx={{mr: 2}}/>
            Ko'rish
          </MenuItem>
        </Link>
        <Link to={`/update/${row.id}`}>
          <MenuItem>
            <Iconify icon={'material-symbols:edit-outline'} sx={{mr: 2}}/>
            O'zgartirish
          </MenuItem>
        </Link>
        {role === 'DEV' && (
          <MenuItem sx={{color: 'error.main'}} onClick={() => orderDelete(row.id)}>
            <Iconify icon={'eva:trash-2-outline'} sx={{mr: 2}}/>
            Delete
          </MenuItem>
        )}
      </Popover>
      <AlertDialog id={row.id} openDialog={openDialog} setOpenDialog={setOpenDialog}
                   updateCheckedMaster={updateCheckedMaster}/>
    </>
  )
}

export default DoingOrderRowMenu
