import { baseApi } from '../base-api';
import { ORDER } from '../urls';

export const orderApiSlice = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    createOrder: builder.mutation({
      query: (credentials) => ({
        url: ORDER.ORDER_CREATE,
        method: 'POST',
        body: credentials,
      }),
    }),
    deleteOrder: builder.mutation({
      query: (arg) => ({
        url: `${ORDER.DELETE_ORDER}/${arg?.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['ORDER'],
    }),
    getOrderList: builder.query({
      query: (arg) =>
        `${ORDER.GET_ORDER_LIST}?status=${arg.status}&itemSize=${arg.itemSize}&pageNumber=${
          arg.pageNumber + 1
        }&searchText=${arg.searchText}&sortBy=LAST_MODIFIED_DATE`,
      providesTags: ['ORDER'],
    }),
    getOrderById: builder.query({
      query: (arg) => `${ORDER.ORDER_GET_BY_ID}${arg.id}`,
    }),
    updateOnlyOrderStatus: builder.mutation({
      query: (arg) => ({
        url: `${ORDER.UPDATE_ORDER_STATUS}/${arg?.id}`,
        method: 'PATCH',
        body: arg?.body,
      }),
      invalidatesTags: ['ORDER'],
    }),
    updateOrder: builder.mutation({
      query: (arg) => ({
        url: `${ORDER.UPDATE_ORDER_STATUS}/${arg?.id}`,
        method: 'PATCH',
        body: arg?.body,
      }),
      invalidatesTags: ['ORDER'],
    }),
    updateOrderDetail: builder.mutation({
      query: (arg) => ({
        url: `${ORDER.UPDATE_ORDER}/${arg?.id}`,
        method: 'PUT',
        body: arg,
      }),
      invalidatesTags: ['ORDER'],
    }),
  }),
});

export const {
  useCreateOrderMutation,
  useGetOrderListQuery,
  useDeleteOrderMutation,
  useUpdateOnlyOrderStatusMutation,
  useGetOrderByIdQuery,
 useUpdateOrderMutation,
  useUpdateOrderDetailMutation
} = orderApiSlice;
