import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {useState} from 'react'
import {Box, TextField} from '@mui/material'

export default function AlertDialog({ row, openDialog, setOpenDialog, updateCheckedStatus, updateCheckedMaster }) {

  const [name, setName] = useState('')

  const handleChange = (e)=> {
    setName(e.target.value)
  }

  const handleClose = () => {
    setOpenDialog(false);
  };
  const handlePaint= ()=>{
    updateCheckedMaster(row.id,'PAINTING', name)
      .then(() => updateCheckedStatus(row.id, 'FINISHED'))
    handleClose()
  }

  const handleDone = ()=>{
    updateCheckedStatus(row.id, 'FINISHED')
    handleClose()
  }

  return (
    <div>
      <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {!row.painterFullName ? (
          <>
            <DialogTitle id="alert-dialog-title">
              {"Buyurtmani bo'yagan ustani kiriting."}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Usta ismini kiriting!
              </DialogContentText>
              <Box sx={{mt:2}}>
                <TextField name={'name'} onChange={handleChange} fullWidth autoFocus={true}/>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color={'warning'}>Ortga</Button>
              <Button onClick={handlePaint} autoFocus>
                Ustani kiritish
              </Button>
              <Button onClick={handlePaint} autoFocus>
                Kiritish va qabul qilish
              </Button>
            </DialogActions>
          </>
          )
          : <>
          <DialogTitle id="alert-dialog-title">
            {"Buyurtmani o'rnatishga tayyor bo'limiga o'tkazish."}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Qaroringiz qat'iymi?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color={'warning'}>Yo'q</Button>
            <Button onClick={handleDone} autoFocus>
              Ha
            </Button>
          </DialogActions>
        </>
        }
      </Dialog>
    </div>
  );
}
