import {createSlice} from '@reduxjs/toolkit'
import Cookies from 'universal-cookie'

const cookies = new Cookies()

const initialState = {
  user: JSON.parse(localStorage.getItem('user')) || null,
  role: JSON.parse(localStorage.getItem('milliydarvozalarRole')) || null,
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginState: (state, action) => {
      const userData = action.payload.data.data
      cookies.set('user', action.payload.data.data)
      cookies.set('role', action.payload.data.data?.role)
      state.user = userData
      state.role = userData.role

      // Store user and role in localStorage
      localStorage.setItem('user', JSON.stringify(userData))
      localStorage.setItem('role', JSON.stringify(userData.role))
    },
  },
})

export const {loginState} = authSlice.actions

export const authReducer = authSlice.reducer

