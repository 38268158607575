import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import Loader from '../components/loader/Loader';
import { useVerifyEmailQuery } from '../api/auth-api/auth-api-slice';
import { EmailVerify } from '../components/email-verify';

const VerifyEmail = () => {
  const { token } = useParams();

  const { isSuccess, isLoading, isFetching } = useVerifyEmailQuery({
    token,
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (!token || (!isSuccess && !isLoading)) {
      navigate('/404');
    }
  }, [token, navigate, isSuccess, isLoading]);

  if (isFetching) {
    return <Loader />;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
      }}
    >
      {!isLoading && <EmailVerify message="Sizning emailingiz mufaqqiyatli tasdiqlandi" isVerify />}
    </Box>
  );
};

export default VerifyEmail;
