// AUTH URLS
export const AUTH = {
  SIGNUP: '/auth/signup',
  RESET_PASSWORD: '/auth/password/reset',
  LOGIN: '/auth/login',
  SET_PASSWORD: '/auth/password/set',
  PASSWORD_CHANGE: '/auth/password/change',
  VALIDATE: '/auth/validate',
  APPROVE: '/auth/approve',
  SIGNUP_VERIFY_EMAIL: '/auth/signup/verify-mail',
  SIGNUP_VERIFY_EMAIL_SEND_TOKEN: '/auth/signup/verify-email/resend/',
};

// ORDER URLS
export const ORDER = {
  ORDER_UPDATE: '/order/update', // it takes id
  ORDER_CREATE: '/order',
  GET_ORDER_LIST: '/order/list',
  ORDER_GET_BY_ID: '/order/',
  DELETE_ORDER: '/order/delete',
  UPDATE_ORDER_STATUS: '/order/update/status',
  UPDATE_ORDER:'/order/update'
};

// FILE REQUEST URLS
export const FILE_UPLOAD = {
  UPLOAD_IMAGE: '/image/upload',
};

// user list api
export const USER = {
  LIST: '/user/list',
  USER_LIST: '/user/list',
  USER_BY_ID: '/user/',
  CUSTUMER_LIST: '/customer/list',
  UPDATE_USER_STATUS: '/user/update/',
  USER_DELETE: '/user/delete/',
};
