import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import {Provider} from "react-redux";
import {Provider as ConfirmProvider} from './context/confirm/index'
import { store } from './store';
// routesProvider
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';


// ----------------------------------------------------------------------

export default function App() {
  return (
    <HelmetProvider>
      <BrowserRouter>
        <ConfirmProvider>
          <ThemeProvider>
            <Provider store={store}>
              <ScrollToTop />
              <StyledChart />
              <Router />
            </Provider>
          </ThemeProvider>
        </ConfirmProvider>
      </BrowserRouter>
    </HelmetProvider>
  );
}
