import {filter} from 'lodash'
import React, {useState} from 'react'
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
} from '@mui/material'
// components
import Scrollbar from '../scrollbar'
// sections
import {UserListHead, UserListToolbar} from '../../sections/@dashboard/user'
import Skeleton from '@mui/material/Skeleton'
import {prop} from 'rambda'

export default function CustomTable({actions, tableHeader, data, loading, pages, setPages, totalElements= 10}) {

  const [page, setPage] = useState(0)

  const [order, setOrder] = useState('asc')

  const [selected, setSelected] = useState([])

  const [orderBy, setOrderBy] = useState('name')

  const [filterName, setFilterName] = useState('')

  const [rowsPerPage, setRowsPerPage] = useState(10)


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.name)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    setPages(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setPage(0)
    setPages(0)
    setRowsPerPage(parseInt(event.target.value, 10))
  }

  const handleFilterByName = (event) => {
    setPage(0)
    setPages(0)
    setFilterName(event.target.value)
  }
  const emptyRows = pages > 0 ? Math.max(0, (1 + pages) * rowsPerPage - data.length) : 0

  const isNotFound = !data.length && !!filterName
  return (
    <Card>
      <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName}/>

      <Scrollbar>
        <TableContainer sx={{minWidth: 800}}>
          <Table>
            <UserListHead
              order={order}
              orderBy={orderBy}
              headLabel={tableHeader}
              rowCount={data.length}
              numSelected={selected.length}
              onRequestSort={handleRequestSort}
              onSelectAllClick={handleSelectAllClick}
            />
            <TableBody>
              {loading ? data.map((row) => {
                const {imagesToken} = row
                const selectedUser = selected.indexOf(row.name) !== -1
                const avatarUrl = `https://api.milliydarvozalar.uz/api/v1/image/view/`
                return (
                  <TableRow hover key={row.id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                    {<TableCell padding="checkbox">
                      {actions ? actions(row) : null}
                    </TableCell>}
                    {tableHeader.map((column, index) => {
                      const columnValue = prop(column.id, row)
                      return (
                        <TableCell scope="row" padding="none" key={index} width={column.width} height={70}>
                          <Stack direction="row" alignItems="center" spacing={2}>
                            {imagesToken && index === 0 &&
                              <Avatar alt={columnValue} src={`${avatarUrl}${imagesToken[0]}`}/>}
                            <Typography variant="subtitle2" noWrap>
                              {columnValue === 'FINISHED' ? 'TUGALLANGAN' : columnValue}
                            </Typography>
                          </Stack>
                        </TableCell>
                      )
                    })}
                  </TableRow>
                )
              }) : <TableRow>
                <TableCell><Skeleton/></TableCell>
                <TableCell><Skeleton/></TableCell>
                <TableCell><Skeleton/></TableCell>
                <TableCell><Skeleton/></TableCell>
                <TableCell><Skeleton/></TableCell>
              </TableRow>}
              {emptyRows > 0 && (
                <TableRow style={{height:'46px'}}>
                  <TableCell colSpan={6}/>
                </TableRow>
              )}
            </TableBody>

            {isNotFound && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={6} sx={{py: 3}}>
                    <Paper
                      sx={{
                        textAlign: 'center',
                      }}
                    >
                      <Typography variant="h6" paragraph>
                        Not found
                      </Typography>

                      <Typography variant="body2">
                        No results found for &nbsp;
                        <strong>&quot;{filterName}&quot;</strong>.
                        <br/> Try checking for typos or using complete words.
                      </Typography>
                    </Paper>
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Scrollbar>

      <TablePagination
        rowsPerPageOptions={[10, 25]}
        component="div"
        count={totalElements}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Card>
  )
}
