export const DEFAULT_ERROR_MESSAGE = "Qandaydir xatolik bo'ldi iltimos birozdan so'ng harakat qilib ko'ring!";

export const ORDER_STATUS = {
  RECEIVED: 'RECEIVED',
  STARTED: 'STARTED',
  PAINTING: 'PAINTING',
  FINISHED: 'FINISHED',
  DELIVERED: 'DELIVERED',
  GOT_PAID:'GOT_PAID',
  DAN: 'DAN',
};
