import { useState } from 'react';
import PropsTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import { Box, IconButton, InputAdornment, TextField } from '@mui/material';
import Iconify from '../iconify/Iconify';

export default function RHFTextField({ name, type, helperText, children, ...other }) {
  const { control } = useFormContext();

  if (type === 'password') {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [showPassword, setShowPassword] = useState(false);

    return (
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            fullWidth
            error={!!error}
            helperText={error?.message}
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            {...other}
          />
        )}
      />
    );
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <>
          <TextField
            {...field}
            fullWidth
            value={typeof field.value === 'number' && field.value === 0 ? '' : field.value}
            error={!!error}
            helperText={error ? error?.message : helperText}
            {...other}
          />

          {children && (
            <Box
              style={{
                width: '100%',
                maxHeight: '120px',
                overflowY: 'auto',
                zIndex: 10000000,
                padding: 1,
              }}
            >
              {children}
            </Box>
          )}
        </>
      )}
    />
  );
}

RHFTextField.propTypes = {
  name: PropsTypes.string,
  type: PropsTypes.string,
  helperText: PropsTypes.string,
  other: PropsTypes.any,
  children: PropsTypes.node,
};
