import React from 'react'
import {Controller, useFormContext} from 'react-hook-form'
import PropTypes from 'prop-types'
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'

RHFDataPicker.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  other: PropTypes.any,
}

export default function RHFDataPicker({name, label, disabled, other}) {
  const {control} = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      render={({field, fieldState: {error}}) => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            format="DD-MM-YYYY"
            label={label}
            disabled={disabled}
            // Ensure the value is a dayjs object
            value={field.value ? dayjs(field.value) : null}
            onChange={(newValue) => {
              // Ensure onChange sends a dayjs object or null if cleared
              field.onChange(newValue ? dayjs(newValue) : null)
            }}
            slotProps={{
              textField: {
                helperText: error?.message,
                error: !!error,
                fullWidth: true,
              },
            }}
            {...other}
          />
        </LocalizationProvider>
      )}
    />
  )
}
