import { memo, useId, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, IconButton, Stack, TextField, Typography } from '@mui/material';
import FileUploadIcon from './FileUploadIcon';
import useResponsive from '../../hooks/useResponsive';
import Iconify from '../iconify';

function FileUploadInput({ name, label, setSelectedFile, selectedFile }) {
  const id = useId();

  const md = useResponsive('down', 575);

  const [isError, setIsError] = useState(false);
  const uploadFiles = (files) => setSelectedFile(files);

  const textColor = isError ? 'error.main' : 'primary.main';

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const uploadedFile = e.dataTransfer.files;
      uploadFiles(uploadedFile);
    }
  };

  const handleFileChange = (event) => {
    uploadFiles(event.target.files);
  };

  const handleDeleteFiles = (files) => {
    const file = [...selectedFile];
    const clearFile = file?.filter((fil) => fil.name !== files.name);
    setSelectedFile(clearFile);
  };

  const handleDragOver = (event) => event.preventDefault();

  return (
    <>
      <Stack
        gap={2}
        flexDirection={md ? 'column' : 'row'}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        sx={{
          borderColor: `${isError ? 'error.main' : 'primary.light'}`,
          borderWidth: 1,
          borderStyle: 'dashed',
          backgroundColor: `${isError ? 'warning.lighter' : 'grey.200'}`,
          borderRadius: 2,
          padding: 2,
        }}
      >
        <FileUploadIcon width={'220px'} heigt={'120px'} />
        <Box>
          <Typography variant={'h4'} sx={{ color: textColor }}>
            {label}
          </Typography>
          <Typography sx={{ color: textColor }}>
            yoki shu yerga tashlang yoki
          </Typography>
         <Button sx={{ marginTop: 2,cursor:'pointer' }} variant={'outlined'}>
          <label htmlFor={id}>
           <input hidden />
           Galareyadan rasm tanlash
          </label>
         </Button>
        </Box>
      </Stack>

      {selectedFile?.length > 0 && (
        <Stack flexDirection={'row'} marginTop={2} flexWrap={'wrap'} gap={2}>
          {Array.from(selectedFile)?.map((file, idx) => (
            <Box key={idx} sx={{ position: 'relative', borderRadius: 1, overflow: 'hidden' }}>
              <Box
                style={{
                  width: '200px',
                  height: '140px',
                  backgroundImage: `url(${URL.createObjectURL(file)})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  position: 'relative',
                }}
              >
                <Box
                  sx={{
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0.4)',
                  }}
                />
                <IconButton
                  sx={{
                    position: 'absolute',
                    top: '0',
                  }}
                  onClick={() => handleDeleteFiles(file)}
                >
                  <Iconify icon={'material-symbols:delete-rounded'} sx={{ color: 'error.main' }} />
                </IconButton>
              </Box>
            </Box>
          ))}
        </Stack>
      )}
      <TextField
        sx={{ display: 'none' }}
        id={id}
        fullWidth
        name={name}
        onChange={handleFileChange}
        type={'file'}
        inputProps={{
          multiple: true,
        }}
      />
      {isError && (
        <>
          <Typography sx={{ color: 'error.main', marginTop: 2 }}>Rasm talab etiladi</Typography>
        </>
      )}
    </>
  );
}

FileUploadInput.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  setSelectedFile: PropTypes.any,
  selectedFile: PropTypes.any,
};

export default memo(FileUploadInput);
