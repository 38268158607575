import {baseApi} from "../base-api";
import {FILE_UPLOAD} from "../urls";

export const fileUploadApiSlice = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        uploadImage: builder.mutation({
            query: (arg) => ({
                url: `${FILE_UPLOAD.UPLOAD_IMAGE}?order-id=${arg?.id}`,
                method: "POST",
                body: arg?.body
            }),
        }),
    }),
});
export const {
    useUploadImageMutation
} = fileUploadApiSlice