import PropTypes from 'prop-types'
// @mui
import {Grid} from '@mui/material'
import ShopProductCard from './ProductCard'
import {boolean} from 'yup'
import Loader from '../../../components/loader'

// ----------------------------------------------------------------------

ProductList.propTypes = {
  products: PropTypes.array.isRequired,
  loading: boolean,
}

export default function ProductList({products, loading, ...other}) {
  return (
    <Grid container spacing={3} {...other}>
      {loading && products.map((product) => (
        <Grid key={product.id} item xs={12} sm={6} md={3}>
          <ShopProductCard product={product}/>
        </Grid>
      ))}
      {!loading && <Loader/>}
    </Grid>
  )
}
