import {Box, FormControlLabel, FormLabel, Radio, RadioGroup} from "@mui/material";
import { useFormContext, Controller } from 'react-hook-form';

export const RHKRadio = ()=>{

 const {control} = useFormContext

 return(
  <Controller
   name="role"
   control={control}
   defaultValue="MASTER"
   render={({ field }) => (
    <>
     <FormLabel>Kasbingizni tanlang:</FormLabel>
     <RadioGroup {...field} label={"Kasbingizni tanlang:"}>
      <Box sx={{display:'flex', alignItems:'center'}}>
       <FormControlLabel value="MASTER" control={<Radio />} label="Usta" />
       <FormControlLabel value="PAINTER" control={<Radio />} label="Bo'yoqchi" />
       <FormControlLabel value="ADMIN" control={<Radio />} label="Ish boshqaruvchi" />
      </Box>
     </RadioGroup>
    </>
 )
}/>
)
}