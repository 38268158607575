import React, {useState} from 'react'
import {Helmet} from 'react-helmet-async'
import {Button, Container, Stack, Typography} from '@mui/material'
import {Link} from 'react-router-dom'
import Iconify from '../../../components/iconify'
import {useCookies} from '../../../hooks/useCookies'
import CustomTable from '../../../components/table/Table'
import UsersRowMenu from '../components/UsersRowMenu'
import {useGetUserListQuery, useUserDeleteMutation} from '../../../api/user-api-req'
import {useConfirm} from '../../../context/confirm'


const TABLE_HEAD = [
  {id: 'fullName', label: 'Ism sharifi', alignRight: false, width: '300'},
  {id: 'email', label: 'Email', alignRight: false},
  {id: 'phoneNumber', label: 'Telefon raqami', alignRight: false},
  {id: 'status', label: 'Holati', alignRight: false},
]

function UsersContainer() {
  const onConfirm = useConfirm()
  const [userDelete, response] = useUserDeleteMutation()
  const [message, setMessage] = useState('')

  const [status, setStatus] = useState('error')
  const {getCookies} = useCookies()

  const token = getCookies('token')

  const {data, isSuccess} = useGetUserListQuery(
    {
      pageNumber: 0,
      searchText: '',
      itemSize: 10,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  )
  const orderDelete = async (id) => {
    const message = `Siz ushbu userni o'chirishga rozimisiz?`

    await onConfirm({ message })
      .agree(() => {
        userDelete({id})
          .then(() => console.log('Muvaffaqqiyatli ochirildi'))
      })
      .disagree()
  }

  return (
    <>
      <Helmet>
        <title>Tugallangan ishlar | Al Baraka</title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Tugallangan ishlar
          </Typography>
          {token ? <Link to={'/order'} style={{textDecoration: 'none'}}>
            <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill"/>}>
              Yangi qo'shish
            </Button>
          </Link> : null}
        </Stack>
        <CustomTable
          loading={isSuccess}
          data={data?.data?.content || []}
          tableHeader={TABLE_HEAD}
          statusOrder={status}
          actions={(row) => <UsersRowMenu row={row} orderDelete={orderDelete} />
          }
        />
      </Container>
    </>
  )
}

export default UsersContainer
