// import {LoaderBeforeAfter, LoaderContainer} from "./styles";
import styled from '@emotion/styled';
import {keyframes} from '@emotion/react';

const rotate = keyframes`
  0% {
    transform: rotate(0deg)
  }
  100% {
    transform: rotate(360deg)
  }
`;

const prixClipFix = keyframes`
  0% {
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0)
  }
  50% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0)
  }
  75%, 100% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%)
  }
`;

export const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`

export const LoaderContainer = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  position: relative;
  animation: ${rotate} 1s linear infinite;
`;

export const LoaderBeforeAfter = styled.div`
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0;
  border-radius: 50%;
  border: 5px solid limegreen;
  animation: ${prixClipFix} 2s linear infinite;

  &:after {
    transform: rotate3d(90, 90, 0, 180deg);
    border-color: dodgerblue;
  }
`;

const Loader = () => (
 <LoaderWrapper>
  <LoaderContainer>
   <LoaderBeforeAfter/>
   <LoaderBeforeAfter/>
  </LoaderContainer>
 </LoaderWrapper>
);
export default Loader