import { Button, Container, Stack, Typography } from '@mui/material';
import {useEffect, useState} from 'react'
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import {
	useDeleteOrderMutation,
	useGetOrderListQuery,
	useUpdateOnlyOrderStatusMutation,
} from '../../../api/order-api/order-api-slice';
import Iconify from '../../../components/iconify';
import CustomTable from '../../../components/table/Table';
import { ORDER_STATUS } from '../../../constants';
import { useCookies } from '../../../hooks/useCookies';
import DoneOrderRowMenu from '../components/DoneOrderRowMenu';
import {useConfirm} from '../../../context/confirm'

const TABLE_HEAD = [
	{ id: 'name', label: 'Buyurtma nomi', alignRight: false, width: '300' },
	{ id: 'price', label: 'Buyurtma olingan summasi', alignRight: false },
	{ id: 'orderTaker', label: 'Buyurtma oluvchi', alignRight: false },
	{ id: 'masterFullName', label: 'Yasagan usta', alignRight: false },
	{ id: 'painterFullName', label: "Bo'yagan usta" },
];

function DoneOrderContainer() {
	const onConfirm = useConfirm()
	const [updateOrderStatus, updateRes] = useUpdateOnlyOrderStatusMutation();
	const [deleteOrder, deleteRes] = useDeleteOrderMutation();
	const [message, setMessage] = useState('');
	const [pages, setPages] = useState(0)
	const [status, setStatus] = useState('error');
	const { getCookies } = useCookies();

	const token = getCookies('token');

	const {
		data: deliveredOrderData,
		isSuccess: recievedSuccess,
		loading: recievedLoading,
		isFetching: recievedFetching,
		refetch
	} = useGetOrderListQuery(
		{
			pageNumber: pages,
			itemSize: 10,
			searchText: '',
			status: ORDER_STATUS.DELIVERED,
		},
		{
			refetchOnMountOrArgChange: true,
		}
	);

	const updateCheckedStatus = async (id, status) => {
		await updateOrderStatus({ id, body: { status } }).then(res => {
			if (res) {
				setMessage("Buyurtma tayyor bo'limiga muvaffaqiyatli o'tkazildi!");
				setStatus('success');
			}
		});
	};

	useEffect(() => {
		refetch();
	}, [pages, refetch])

	const orderDelete = async id => {
		const message = `Siz ushbu buyurtmani o'chirishga rozimisiz?`

		await onConfirm({ message })
			.agree(() => {
				deleteOrder({ id }).then(() => {
					setMessage("Buyurtma tayyor bo'limiga muvaffaqiyatli o'tkazildi!");
					setStatus('success');
					refetch()
				});
			})
			.disagree()
	};

	return (
		<>
			<Helmet>
				<title>Tugallangan ishlar | Al Baraka</title>
			</Helmet>
			<Container>
				<Stack direction='row' alignItems='center' justifyContent='space-between' mb={5}>
					<Typography variant='h4' gutterBottom>
						Tugallangan ishlar
					</Typography>
					{token ? (
						<Link to={'/order'} style={{ textDecoration: 'none' }}>
							<Button variant='contained' startIcon={<Iconify icon='eva:plus-fill' />}>
								Yangi qo'shish
							</Button>
						</Link>
					) : null}
				</Stack>
				<CustomTable
					loading={recievedSuccess}
					data={deliveredOrderData?.data?.content || []}
					totalElements={deliveredOrderData?.data?.totalElements}
					pages={pages}
					setPages={setPages}
					tableHeader={TABLE_HEAD}
					statusOrder={'DELIVERED'}
					actions={row => (
						<DoneOrderRowMenu
							row={row}
							orderDelete={orderDelete}
							updateCheckedStatus={updateCheckedStatus}
						/>
					)}
				/>
			</Container>
		</>
	);
}

export default DoneOrderContainer;
