import {useState} from 'react'
// @mui
import {Menu, Button, MenuItem, Typography} from '@mui/material'
// component
import Iconify from '../../../components/iconify'

// ----------------------------------------------------------------------

const SORT_BY_OPTIONS = [
  {value: 'ALL', label: 'Hammasi'},
  {value: 'RECEIVED', label: 'Yangi olingan'},
  {value: 'STARTED', label: 'Yasalmoqda'},
  {value: 'PAINTING', label: 'Bo\'yalmoqda'},
  {value: 'FINISHED', label: 'O\'rnatishga tayyor'},
  {value: 'DELIVERED', label: 'O\'rnatilgan'}
]

export default function ShopProductSort({ setStatus }) {
  const [open, setOpen] = useState(null)
  const [title, setTitle] = useState('Hammasi')

  const handleOpen = (event) => {
    setOpen(event.currentTarget)
  }

  const handleClose = () => {
    setOpen(null)
  }

  const handleClick = (value,title) => {
    setStatus(value)
    setTitle(title)
    handleClose()
  }

  return (
    <>
      <Button
        color="inherit"
        disableRipple
        onClick={handleOpen}
        endIcon={<Iconify icon={open ? 'eva:chevron-up-fill' : 'eva:chevron-down-fill'}/>}
      >
        Saralash : &nbsp;
        <Typography component="span" variant="subtitle2" sx={{color: 'text.secondary'}}>
          {title}
        </Typography>
      </Button>
      <Menu
        keepMounted
        anchorEl={open}
        open={Boolean(open)}
        onClose={handleClose}
        anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
        transformOrigin={{vertical: 'top', horizontal: 'right'}}
      >
        {SORT_BY_OPTIONS.map((option) => (
          <MenuItem
            key={option.value}
            selected={option.value === 'ALL'}
            onClick={() => handleClick(option.value,option.label)}
            sx={{typography: 'body2'}}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
