import React, {useState} from 'react'
import {Helmet} from 'react-helmet-async'
import {Button, Container, Stack, Typography} from '@mui/material'
import {Link} from 'react-router-dom'
import Iconify from '../../../components/iconify'
import {useCookies} from '../../../hooks/useCookies'
import CustomTable from '../../../components/table/Table'
import {
  useDeleteOrderMutation,
  useGetOrderListQuery,
  useUpdateOnlyOrderStatusMutation, useUpdateOrderMutation,
} from '../../../api/order-api/order-api-slice'
import {ORDER_STATUS} from '../../../constants'
import PaintingOrderRowMenu from '../components/PaintingRowMenu'
import {useConfirm} from '../../../context/confirm'


const TABLE_HEAD = [
  {id: 'name', label: 'Buyurtma nomi', alignRight: false, width:300},
  {id: 'acceptedDate', label: 'Buyurtma olingan sana', alignRight: false},
  {id: 'address', label: 'Manzil', alignRight: false},
  {id: 'painterFullName', label: "Bo'yayotgan usta", alignRight: false},
]

function PaintingOrderContainer() {
  const onConfirm = useConfirm()
  const {getCookies} = useCookies()

  const token = getCookies('token')
  const [updateOrderStatus, updateRes] = useUpdateOnlyOrderStatusMutation();
  const [updateOrder,updateResOr] = useUpdateOrderMutation()
  const [deleteOrder, deleteRes] = useDeleteOrderMutation();
  const [message,setMessage] = useState("")

  const [status,setStatus] = useState('error')


  const {
    data: paintingOrderData,
    isSuccess: recievedSuccess,
    loading: recievedLoading,
    isFetching: recievedFetching,
  } = useGetOrderListQuery(
    {
      pageNumber: 0,
      itemSize: 10,
      searchText: '',
      status: ORDER_STATUS.PAINTING,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  )
  const updateCheckedMaster = async (id, status,workerFullName) => {
    await updateOrder({ id, body: { status,workerFullName } }).then((res) => {
      if (res.data?.success){
        setMessage("Buyurtmaga bo'yagan usta qo'shildi!")
        setStatus('success')
      }
    });
  };

  const updateCheckedStatus = async (id, status) => {
    await updateOrderStatus({ id, body: { status } }).then((res) => {
      if (res){
        setMessage("Buyurtma tayyor bo'limiga muvaffaqiyatli o'tkazildi!")
        setStatus('success')
      }
    });
  };
  const orderDelete = async (id)=>{
    const message = `Siz ushbu buyurtmani o'chirishga rozimisiz?`

    await onConfirm({ message })
      .agree(() => {
        deleteOrder({id})
          .then(()=>console.log('Muvaffaqqiyatli ochirildi'))
      })
      .disagree()
  }

  return (
    <>
      <Helmet>
        <title>Bo'yoqdagi ishlar | Al Baraka</title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Bo'yoqdagi ishlar
          </Typography>
          {token ? <Link to={'/order'} style={{textDecoration: 'none'}}>
            <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill"/>}>
              Yangi qo'shish
            </Button>
          </Link> : null}
        </Stack>
        <CustomTable
          loading={recievedSuccess}
          data={paintingOrderData?.data?.content || []}
          tableHeader={TABLE_HEAD}
          statusOrder={'PAINTING'}
          actions={(row)=><PaintingOrderRowMenu
            row={row}
            orderDelete={orderDelete}
            updateCheckedStatus={updateCheckedStatus}
            updateCheckedMaster={updateCheckedMaster}
          />
          }
        />
      </Container>
    </>
  )
}

export default PaintingOrderContainer
