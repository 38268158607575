// @mui
import { LoadingButton } from '@mui/lab';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { Alert } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import FormProvider from '../../../components/rxf-form-provider';
import RHFTextField from '../../../components/rhf-text-field';
import { useResetPasswordMutation } from '../../../api/auth-api/auth-api-slice';

// ----------------------------------------------------------------------

ForgotPasswordForm.propTypes = {
  getMail: PropTypes.func.isRequired,
};

export default function ForgotPasswordForm({ getMail }) {
  const [resetPassword] = useResetPasswordMutation();

  const registerFormSchema = yup.object({
    email: yup.string().email('Email formati xato!').required('Elektron pochta manzilini kiriting'),
  });

  const defaultValues = {
    email: '',
  };

  const methods = useForm({
    resolver: yupResolver(registerFormSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    try {
      await resetPassword(data).then((res) => {
        if (res.error) {
          throw res;
        }
        getMail(data.email);
      });
    } catch (error) {
      let errorMsg = '';
      const errData = error.error?.data?.errors ?? error.error?.data;
      for (let i = 0; i < errData?.length; i += 1) {
        errorMsg += ` ${errData[i].message}`;
      }
      reset();
      setError('root.register', {
        message: errorMsg,
      });
    }
  };

  return (
    <>
      <FormProvider onSubmit={handleSubmit(onSubmit)} methods={methods}>
        {errors && errors.root && errors.root.register && (
          <Alert severity="error" sx={{ marginBottom: 2 }}>
            {errors.root.register.message}
          </Alert>
        )}
        <RHFTextField name={'email'} label="Elektron pochta manzilini kiriting" />
        <LoadingButton
          sx={{ marginTop: 2 }}
          loading={isSubmitting}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
        >
          Parolni tiklash
        </LoadingButton>
      </FormProvider>
    </>
  );
}
