import { Link, useNavigate } from 'react-router-dom';
import {useDispatch} from "react-redux";
// @mui
import { Stack, Alert, Box, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLoginMutation } from '../../../api/auth-api/auth-api-slice';

// components

import FormProvider from '../../../components/rxf-form-provider';
import RHFTextField from '../../../components/rhf-text-field';
import {loginState} from "../../../store/auth-slice";

// ----------------------------------------------------------------------

export default function LoginForm() {
  const loginFormSchema = yup.object({
    email: yup.string().email('Email formati xato!').required('Elektron pochta manzilini kiriting'),
    password: yup.string().min(8, 'Minimal parol uzunligi 8 ta').required('Parolni kiriting'),
  });

  const navigate = useNavigate();

  const defaultValues = {
    email: '',
    password: '',
  };

  const methods = useForm({
    resolver: yupResolver(loginFormSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    setError,
    reset,
    formState: { isSubmitting, errors },
  } = methods;

  const dispatch = useDispatch()
  const [login] = useLoginMutation();

  const onSubmit = async (data) => {
    try {
      await login(data).then((res) => {
        if (res?.error) {
          throw res;
        }
        dispatch(loginState(res.data))
        navigate('/works');
      });
    } catch (error) {
      let errorMsg = '';
      const errData = error.error?.data?.errors ?? error.error?.data;
      for (let i = 0; i < errData?.length; i += 1) {
        errorMsg += ` ${errData[i].message}`;
      }
      reset();
      setError('root.register', {
        message: errorMsg,
      });
    }
  };
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={2}>
        {errors && errors.root && errors.root.register && (
          <Alert severity="error">{errors.root.register.message}</Alert>
        )}
        <RHFTextField name={'email'} label={'Emailingizni yozing'} />
        <RHFTextField name={'password'} label={'Parolingizni yozing'} type={'password'} />
      </Stack>
      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <Stack />
        <Link to={'/fotgot-password'}>
          <Typography variant={'span'} sx={{ color: '#1C9CEA' }}>
            Parolni tiklash?
          </Typography>
        </Link>
      </Stack>

      <Box sx={{ marginTop: 2 }}>
        <LoadingButton loading={isSubmitting} fullWidth size="large" type="submit" variant="contained">
          Login
        </LoadingButton>
      </Box>
    </FormProvider>
  );
}
