import Cookies from 'universal-cookie';
import {baseApi} from '../base-api';
import {AUTH} from '../urls';

const cookies = new Cookies();

export const authApiSlice = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        login: builder.mutation({
            query: (credentials) => ({
                url: '/auth/login',
                method: 'POST',
                body: {...credentials},
            }),
            transformResponse: (res) => {
                cookies.set('token', res.data.token)
                return res;
            },
        }),
        register: builder.mutation({
            query: (credentials) => ({
                url: AUTH.SIGNUP,
                method: 'POST',
                body: {...credentials},
            }),
        }),
        resetPassword: builder.mutation({
            query: (credentials) => ({
                url: AUTH.RESET_PASSWORD,
                method: 'POST',
                body: {...credentials},
            }),
        }),
        setPassword: builder.mutation({
            query: (arg) => ({
                url: AUTH.SET_PASSWORD,
                method: 'PATCH',
                body: arg,
            }),
        }),
        passwordTokenVerify: builder.query({
            query: (arg) => `${AUTH.VALIDATE}/${arg.token}`,
        }),
        verifyEmail: builder.query({
            query: (arg) => `${AUTH.SIGNUP_VERIFY_EMAIL}/${arg.token}`,
        }),
    }),
});

export const {
    useLoginMutation,
    useRegisterMutation,
    usePasswordTokenVerifyQuery,
    useResetPasswordMutation,
    useSetPasswordMutation,
    useVerifyEmailQuery,
} = authApiSlice;
