import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {useState} from 'react'
import {Box, TextField} from '@mui/material'

export default function AlertDialog({ id,openDialog, setOpenDialog, updateCheckedMaster }) {
  const [name, setName] = useState('')
  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleChange = (e)=> {
    setName(e.target.value)
  }



  const handleClick = ()=>{
    updateCheckedMaster(id,'STARTED',name)
    handleClose()
  }

  return (
    <div>
      <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Buyurtmani yasalomoqda bo'limiga o'tkazish."}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Usta ismini kiriting!
          </DialogContentText>
          <Box sx={{mt:2}}>
            <TextField name={'name'} onChange={handleChange} fullWidth autoFocus={true}/>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color={'warning'}>Ortga</Button>
          <Button onClick={handleClick} autoFocus>
            Kiritish
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}