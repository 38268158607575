import {useState} from 'react';
import {Helmet} from 'react-helmet-async';
// @mui
import {styled} from '@mui/material/styles';
import {Container, Typography, Divider} from '@mui/material';
import {Link} from 'react-router-dom';
// hooks
import {EmailVerify} from '../components/email-verify';
import useResponsive from '../hooks/useResponsive';
// components
import {RegisterForm} from '../sections/auth/register';
import Logo from '../components/logo';
// sections

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({theme}) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex',
    },
}));

const StyledSection = styled('div')(({theme}) => ({
    width: '100%',
    maxWidth: 480,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    boxShadow: theme.customShadows.card,
    backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({theme}) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function RegisterPage() {
    const mdUp = useResponsive('up', 'md');
    const [email, setEmail] = useState('');

    const getEmail = (email) => setEmail(email);

    return (
        <>
            <Helmet>
                <title> Ro'yhatdan o'tish sahifasi | Al Baraka </title>
            </Helmet>

            <StyledRoot>
                <Logo
                    sx={{
                        position: 'fixed',
                        top: {xs: 16, sm: 24, md: 40},
                        left: {xs: 16, sm: 24, md: 40},
                    }}
                />
                {mdUp && (
                    <StyledSection>
                        <Typography variant="h3" sx={{px: 5, mt: 10, mb: 5}}>
                            Salom, Qaytib kelganingizdan xursandmiz!
                        </Typography>
                        <img src="/assets/illustrations/illustration_login.png" alt="login"/>
                    </StyledSection>
                )}

                <Container maxWidth="sm">
                    <StyledContent>
                        {email === '' ? (
                            <>
                                <Typography variant="h4" gutterBottom>
                                    Ro'yhatdan o'tish
                                </Typography>
                                <Typography variant="body" sx={{mb: 5}}>
                                    Akkountingiz bormi?
                                    <Link to={'/login'}>
                                        <Typography variant={'span'} sx={{color: '#1C9CEA'}}>
                                            Akkauntga kirish.
                                        </Typography>
                                    </Link>
                                </Typography>
                                <Divider sx={{my: 2}}>
                                    <Typography variant="body2" sx={{color: 'text.secondary'}}>
                                        YOKI
                                    </Typography>
                                </Divider>
                                <RegisterForm getEmail={getEmail}/>
                            </>
                        ) : (
                            <EmailVerify
                                mail={email}
                                isVerify={false}
                                message="Emailingizni tasdiqlang va biz bilan darvozalar dunyosiga safaringizni davom ettiring"
                            />
                        )}
                    </StyledContent>
                </Container>
            </StyledRoot>
        </>
    );
}
