import {lazy, Suspense, useEffect, useMemo} from 'react';

import {Navigate, useRoutes} from 'react-router-dom';
// layouts
import SimpleLayout from './layouts/simple';
//
import BlogPage from './pages/BlogPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/404';
import Loader from './components/loader';
import RegisterPage from './pages/Register';
import ForgotPasswordPage from './pages/ForgotPassword';
import OrderPage from './pages/OrderPage';
import VerifyEmail from './pages/VerifyEmail';
import ResetPasword from './pages/ResetPassword';
import {useCookies} from './hooks/useCookies';
import CustomTable from "./components/table/Table";
import NewOrderContainer from "./pages/new-order/containers/NewOrderContainer";
import DoingOrderContainer from './pages/doing/containers/DoingOrderContainers'
import PaintingOrderContainer from './pages/painting/containers/PaintingOrderContainer'
import ReadyOrderContainer from './pages/ready/containers/ReadyOrderContainer'
import DoneOrderContainer from './pages/done/containers/DoneOrderConatiner'
import UsersContainer from './pages/users/containers/UsersContainer'
import OrdersPage from './pages/orders/containers/OrderPageContainer'
import GotPaidOrderConatiner from './pages/got-paid/containers/GotPaidOrderConatiner'

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) =>
 (
  <Suspense fallback={<Loader/>}>
   <Component {...props} />
  </Suspense>
 );

const DashboardLayoutScreen = Loadable(lazy(() => import('./layouts/dashboard')));

const DetailPage = Loadable(lazy(() => import('./pages/detail/containers/DetailContainer')));
const UpdateOrderContainer = Loadable(lazy(() => import('./pages/update-order/containers/UpdateOrderContainer')))


export default function Router() {
 const {getCookies} = useCookies();

 const token = getCookies('token');
 const getUser = getCookies('user')

 const isAuthorized = useMemo(() => Boolean(token), [token]);

 useEffect(() => {

 }, [token, getUser])

 const privateRoutes = [
  {
   path: '/',
   element: <DashboardLayoutScreen getUser={getUser}/>,
   children: [
    {element: <Navigate to="/all"/>, index: true},
    {path: 'user', element: <UsersContainer />},
    {path: 'works', element: <OrdersPage/>},
    {
     path: 'donework',
     element: <DoneOrderContainer/>,
    },
    {
     path: 'got-paid',
     element: <GotPaidOrderConatiner/>,
    },
    {
     path:'table',
     element: <CustomTable/>
    },
    {
     path:'received',
     element: <NewOrderContainer/>
    },
    {
     path:'doing',
     element: <DoingOrderContainer/>
    },
    {
     path:'painting',
     element: <PaintingOrderContainer />
    },
    {
     path:'ready',
     element: <ReadyOrderContainer/>
    },
    {path: 'blog', element: <BlogPage/>},
    {
     path: 'order',
     element: <OrderPage/>,
    },
    {
     path: 'order/:id',
     element: <DetailPage/>,
    },
    {
     path: 'update/:id',
     element: <UpdateOrderContainer/>,
    },
    {path: '404', element: <Page404/>}
   ],
  },
  {
   path: '*',
   element: <Navigate to="/404" replace/>,
  },
 ];

 const publicRoutes = [
  {
   path: '/',
   element: <DashboardLayoutScreen getUser={getUser}/>,
   children: [
    {element: <Navigate to="/works"/>, index: true},
    {path: 'works', element: <OrdersPage/>},
    {
     path: 'order/:id',
     element: <DetailPage/>,
    },
   ],
  },
  {
   path: 'login',
   element: <LoginPage/>,
  },
  {path: 'register', element: <RegisterPage/>},
  {
   path: 'fotgot-password',
   element: <ForgotPasswordPage/>,
  },
  {
   path: 'verify-mail/:token',
   element: <SimpleLayout/>,
   children: [
    {
     path: '',
     element: <VerifyEmail/>,
    },
   ],
  },
  {
   path: 'validate/:token',
   element: <SimpleLayout/>,
   children: [
    {
     path: '',
     element: <ResetPasword/>,
    },
   ],
  },
  {
   path: '*',
   element: <Navigate to="/404" replace/>,
  },
  {
   path: '404',
   element: <Page404/>
  },
 ];

 return useRoutes(isAuthorized ? privateRoutes : publicRoutes);
}
