import * as yup from 'yup';
import PropTypes from 'prop-types';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import { Stack, Alert, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import FormProvider from '../../../components/rxf-form-provider';
import RHFTextField from '../../../components/rhf-text-field';
import { useRegisterMutation } from '../../../api/auth-api/auth-api-slice';

// components
import { useCookies } from '../../../hooks/useCookies';
import {RHKRadio} from "../../../components/rhk-radio/radio";

// ----------------------------------------------------------------------

export default function RegisterForm({ getEmail }) {
  const navigate = useNavigate();

  const registerFormSchema = yup.object({
    fullName: yup.string().required("To'liq ism sharifingizni yozing!"),
    email: yup.string().email('Email formati xato!').required('Elektron pochta manzilini kiriting'),
    password: yup.string().min(8, 'Minimal parol uzunligi 8 ta').required('Parolni kiriting'),
    phoneNumber: yup
      .string()
      .max(13, "O'zbekiston xududida shu formatdagi raqamlar mavjud")
      .required('Telefon raqamingizni yozing'),
  });

  const defaultValues = {
    fullName: '',
    email: '',
    password: '',
    phoneNumber: '+998',
  };

  const methods = useForm({
    resolver: yupResolver(registerFormSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const { getCookies } = useCookies();
  const token = getCookies('token');
  const [registerMutation] = useRegisterMutation();

  const onSubmit = async (data) => {
    try {
      await registerMutation(data).then((res) => {
        if (res.error) {
          throw res;
        }
        getEmail(data.email);
      });
    } catch (error) {
      let errorMsg = '';
      const errData = error.error?.data?.errors ?? error.error?.data;
      for (let i = 0; i < errData?.length; i += 1) {
        errorMsg += ` ${errData[i].message}`;
      }
      reset();
      setError('root.register', {
        message: errorMsg,
      });
    }
  };

  if (token) {
    navigate('/works', { replace: true });
  }

  return (
    <>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Box>
          <Stack spacing={3} sx={{ my: 2 }}>
            {errors && errors.root && errors.root.register && (
              <Alert severity="error">{errors.root.register.message}</Alert>
            )}
            <RHFTextField name={'fullName'} label="FIO." />
            <RHFTextField name={'email'} label="Elektron pochtangizni yozing!" />
            <RHFTextField
              name={'phoneNumber'}
              label="Telefon raqamingizni kiriting"
              inputProps={{
                maxLength: 13,
              }}
            />
            <RHFTextField name={'password'} label="Tasdiqlash parolini yozing" type={"password"} />
            <RHKRadio/>
          </Stack>
          <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
            Ro'yxatdan o'tish
          </LoadingButton>
        </Box>
      </FormProvider>
    </>
  );
}

RegisterForm.propTypes = {
  getEmail: PropTypes.func.isRequired,
};
