import {configureStore} from "@reduxjs/toolkit";
import {setupListeners} from "@reduxjs/toolkit/dist/query";
import {baseApi} from "../api/base-api";
import {authReducer} from "./auth-slice";
import orderReducer from "./order-store"

export const store = configureStore({
 reducer: {
  [baseApi.reducerPath]: baseApi.reducer,
  auth: authReducer,
  order: orderReducer
 },
 middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware().concat(baseApi.middleware),
 devTools: true,
});

setupListeners(store.dispatch);
