import { memo } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import Iconify from '../iconify/Iconify';

const StyledRoot = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  span: {
    textAlign: 'center',
    maxWidth: '350px',
    width: '100%',
  },
}));

const StyledMail = styled('span')(() => ({
  fontWeight: 'bold',
}));

export const EmailVerify = memo(({ mail, isVerify = false, message }) => {
  const color = isVerify ? '#00BFFF' : '#5291E4';
  return (
    <StyledRoot>
      <Iconify
        icon={isVerify ? 'material-symbols:mark-email-read-outline' : 'material-symbols:mark-email-unread-outline'}
        width={100}
        heigt={100}
        sx={{ color }}
      />

      <Typography variant="paragraphy" sx={{ color }}>
        {message}
      </Typography>
      <StyledMail sx={{ color }}>{mail}</StyledMail>
    </StyledRoot>
  );
});

EmailVerify.propTypes = {
  isVerify: PropTypes.bool,
  message: PropTypes.string.isRequired,
  mail: PropTypes.string,
};
