import Cookies from "universal-cookie";


export const useCookies = () => {
  const cookies = new Cookies();

  const getCookies = (name) => cookies.get(name);
  const deleteCookies = (name) => cookies.delete(name);
  const setCookies = (name, value) => cookies.set(name, value);

  return {
    getCookies,
    setCookies,
    deleteCookies,
  };
};
