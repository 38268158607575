import { Container, Stack, Button, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import {Link} from "react-router-dom";


import { NewOrder } from '../sections/@dashboard/order/NewOrder';


export default function OrderPage() {
  return (
    <>
      <Helmet>
        <title> Yangi zakaz | Minimal UI </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Yangi zakaz
          </Typography>
         <Link to={'/received'} style={{textDecoration:"none"}}>
          <Button variant="contained" sx={{backgroundColor:'#F07427', color:'#fff'}}>
           Ortga
          </Button>
         </Link>
        </Stack>  
        <NewOrder />
      </Container>
    </>
  );
}
