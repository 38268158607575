import {Helmet} from 'react-helmet-async'
import {Button, Container, Stack, Typography} from '@mui/material'
import {Link} from 'react-router-dom'
import Iconify from '../../../components/iconify'
import {useCookies} from '../../../hooks/useCookies'
import CustomTable from '../../../components/table/Table'
import {
  useDeleteOrderMutation,
  useGetOrderListQuery,
  useUpdateOrderMutation,
} from '../../../api/order-api/order-api-slice'
import {ORDER_STATUS} from '../../../constants'
import DoingOrderRowMenu from '../components/DoingOrderRowMenu'
import {useState} from 'react'
import {CustomAlert} from '../../../components/custom-alert'
import {useConfirm} from '../../../context/confirm'

const TABLE_HEAD = [
  {id: 'name', label: 'Buyurtma nomi',alignRight: false, width:300},
  {id: 'acceptedDate', label: 'Buyurtma olingan sana', alignRight: false},
  {id: 'address',label: 'Manzil', alignRight: false,},
  {id: 'masterFullName', label: 'Bajarayotgan usta', alignRight: false}
]
function DoingOrderContainer() {
  const onConfirm = useConfirm()
  const [message,setMessage] = useState("")

  const [status,setStatus] = useState('error')
  const {getCookies} = useCookies()

  const token = getCookies('token')

  const {
    data: startedOrderData, isSuccess: recievedSuccess, loading: recievedLoading, isFetching: recievedFetching,
  } = useGetOrderListQuery({
    pageNumber: 0, itemSize: 10, searchText: '', status: ORDER_STATUS.STARTED,
  }, {
    refetchOnMountOrArgChange: true,
  })

  const [deleteOrder, deleteRes] = useDeleteOrderMutation();
  const [updateOrder,updateResOr] = useUpdateOrderMutation()

  const updateCheckedMaster = async (id, status,workerFullName) => {
    await updateOrder({ id, body: { status,workerFullName } }).then((res) => {
      if (res.data?.success){
        setMessage("Buyurtma yasalmoqda bo'limiga muvaffaqqiyatli o'tkazildi!")
        setStatus('success')
      }
    });
  };
  const orderDelete = async (id)=> {
    const message = `Siz ushbu buyurtmani o'chirishga rozimisiz?`

    await onConfirm({ message })
      .agree(() => {
        deleteOrder({id})
          .then(()=>console.log('Muvaffaqqiyatli ochirildi'))
      })
      .disagree()

  }

  return (<>
    <Helmet>
      <title> Bajarilayotgan ishlar | Al Baraka</title>
    </Helmet>
    {message && <CustomAlert status={status} message={message}/>}
    <Container>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          Bajarilayotgan ishlar
        </Typography>
        {token ? <Link to={'/order'} style={{textDecoration: 'none'}}>
          <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill"/>}>
            Yangi qo'shish
          </Button>
        </Link> : null}
      </Stack>
      <CustomTable
        loading={recievedSuccess}
        data={startedOrderData?.data?.content || []}
        tableHeader={TABLE_HEAD}
        statusOrder={'STARTED'}
        actions={(row)=><DoingOrderRowMenu row={row} orderDelete={orderDelete} updateCheckedMaster={updateCheckedMaster}/>}
      />
    </Container>
  </>)
}

export default DoingOrderContainer
