import React, {useState} from 'react';
import {Alert, Box} from '@mui/material';

export const CustomAlert = ({status, message}) => {
 const [open, setOpen] = useState(true);

 React.useEffect(() => {
  const timer = setTimeout(() => {
   setOpen(false);
  }, 2500);
  return () => clearTimeout(timer);
 }, []);

 return (
  <>{open &&
   <Box sx={{position: 'absolute', top: '10',left:'50%',transform:'translate(-50%,50%)'}}>
    <Alert
     anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
     variant="filled"
     severity={status === "error" ? "error" : "success"}
     open={open}
    >
     {message}
    </Alert>
   </Box>
  }
  </>

 );
}