import { baseApi } from '../base-api';
import {ORDER, USER} from '../urls';

export const userApiSlice = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getUserList: builder.query({
      query: (arg) =>
        `${USER.LIST}?status=ALL&itemSize=${arg.itemSize}&pageNumber=${arg.pageNumber + 1}&searchText=${
          arg.searchText
        }`,
      providesTags: ['USER'],
    }),

    updateUserStatus: builder.mutation({
      query: (arg) => ({
        url: `${USER.UPDATE_USER_STATUS}${arg.id}?`,
        method: 'PATCH',
        body: {
          status: arg.status,
        },
      }),
      invalidatesTags: ['USER'],
    }),
    userDelete: builder.mutation({
      query: (arg) => ({
        url: `${USER.USER_DELETE}${arg.id}?`,
        method: 'DELETE',
        body: {
          id:arg.id
        },
      }),
      invalidatesTags: ['USER'],
    }),
    getUserByIdTaker: builder.query({
      query: (arg) => `${USER.USER_BY_ID}${arg.userId}`,
    }),
    getUserByIdMaster: builder.query({
      query: (arg) => `${USER.USER_BY_ID}${arg.masterId}`,
    }),
    getUserByIdPainter: builder.query({
      query: (arg) => `${USER.USER_BY_ID}${arg.painterId}`,
    }),
  }),
});

export const { useGetUserListQuery, useUpdateUserStatusMutation,useGetUserByIdTakerQuery,useGetUserByIdMasterQuery,useGetUserByIdPainterQuery,useUserDeleteMutation } = userApiSlice;
